.edit_post {
    display: flex;
    flex-direction: column;
    position: relative;
    top: -14px;
}

#create-post-header {
    text-align: center;
    margin-bottom: 7px;
    border-bottom: 1px solid rgb(144, 144, 144);
}

#edit-post-header {
    text-align: center;
    margin-bottom: 7px;
    border-bottom: 1px solid rgb(144, 144, 144);
}

#user-card{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 7px;
    margin-top: -10px;
    margin-bottom: -10px;
    /* justify-content: center; */
}

#name{
    margin-left: 10px;
}

#text-area{
    margin-top: 14px;
    border: none;
    resize: none;
    outline:0px none transparent;
    font-size: large;
}

#edit-button {
    background-color: #2374E1;
    color: white;
    font-size: 14px;
    border-radius: 7px;
    border: none;
    height: 35px;
    font-weight: 600;
}

#edit-button:hover {
    background-color: #1877F2;
    cursor: pointer;
}
