.fa-right-from-bracket{
    font-size: 15px;
    width: 75px;
}

.profile-dropdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    position: absolute;
    padding: 10px 10px 10px 10px;
    width: 222px;
    top: 32px;
    right: 12px;
    border: solid .5px rgb(225, 225, 225);
    border-radius: 7px;
    overflow: auto;
    box-shadow: 0.5px 3px 3px 0px rgba(120, 120, 120, 0.4);
}

.nav-icon{
    border-radius: 444px;
    border: none;
}

#profile-pic{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 35px;
    border: none;
}

#profile-pic:hover{
    cursor: pointer;
}

.prof-buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    font-size: 17px;
    width: 100%;
    height: 33px;
    margin: 3px;
    background-color: inherit;
    border: none;
    border-radius: 7px;
}

.prof-buttons:hover{
    background-color: rgb(234, 233, 233);
    cursor: pointer;
}
