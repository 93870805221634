.post{
    display: flex;
    flex-direction: column;
    /* background-color: #242526;
    color: white */
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0 1px 1px 1px rgba(202, 201, 201, 0.632);
    padding: 20px;
    width: 555px;
    margin: auto;
    margin-top: 15px;
    margin-bottom: 15px;
}

.user-bar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* background-color: #242526; */
    background-color: #fff;
}

.cl-bar{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 2px;
    border-top: solid 1px rgba(202, 201, 201, 0.632);
    border-bottom: solid 1px rgba(202, 201, 201, 0.632);
    padding-top: 14px;
    padding-bottom: 14px;
}

.user{
    display: flex;
    flex-direction: row;
    align-items: center;
}

#user-name{
    margin-left: 10px;
}

.fa-user{
    font-size: 20px;
}

.edit-delete{
    display: flex;
    flex-direction: row;
}

.edit-delete-buttons{
    height: 21.5px;
    width: 37px;
    border: none;
}

.edit-delete-buttons:hover{
    cursor: pointer;
    background-color: rgb(234, 233, 233);
}

.likes-div:hover{
    cursor: pointer;
}

#add-like:hover{
    cursor: pointer;
}

#delete-like:hover{
    cursor: pointer;
}
