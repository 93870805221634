#signup{
    background-color: #43aa2e;
    color: #FFFFFF;
    font-size: 17px;
    border-radius: 7px;
    border: none;
    font-weight: bold;
    width: 280px;
    height: 30px;
}

#signup:hover{
    cursor: pointer;
    background-color: #3b9928;
}
